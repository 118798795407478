import CardAuthorBox from "components/CardAuthorBox/CardAuthorBox";
import CardAuthorBox2 from "components/CardAuthorBox2/CardAuthorBox2";
import Heading from "components/Heading/Heading";
import { DEMO_AUTHORS } from "data/authors";
import { AuthorType } from "data/types";
import { FC, useEffect, useMemo } from "react";
import Glide from "@glidejs/glide";
import useNcId from "hooks/useNcId";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import day1 from "images/01_black.png";
import day2 from "images/02_black.png";
import day3 from "images/03_black.png";
import day4 from "images/04_black.png";
import day5 from "images/05_black.png";
// import day6 from "images/06_black.png";
import ADHD from "images/ADHD.png";
import NextPrev from "shared/NextPrev/NextPrev";

export interface SectionGridAuthorBoxProps {
  className?: string;
  authors?: any[];
  boxCard?: "box1" | "box2";
  gridClassName?: string;
}

const DEMO_DATA = [
  {
    id: 1,
    firstName: "Alric",
    lastName: "Truelock",
    displayName: "DAY 1",
    email: "atruelock0@skype.com",
    gender: "Bigender",
    avatar: "https://uifaces.co/our-content/donated/1H_7AxP0.jpg",
    bgImage: day1,
    count: 40,
    href: "/author",
    desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
    jobName: "Author Job",
    pdfUrl: "/Day1.pdf",
  },
  {
    id: 2,
    firstName: "Chariot",
    lastName: "Birrell",
    displayName: "DAY 2",
    email: "cbirrell1@google.com.hk",
    gender: "Genderfluid",
    avatar: "https://uifaces.co/our-content/donated/gPZwCbdS.jpg",
    pdfUrl: "/Day2.pdf",
    count: 113,
    href: "/author",
    desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
    jobName: "Author Job",
    bgImage: day2,
  },
  {
    id: 3,
    firstName: "Nathanil",
    lastName: "Foulcher",
    displayName: "DAY 3",
    email: "nfoulcher2@google.com.br",
    gender: "Bigender",
    avatar: "https://uifaces.co/our-content/donated/3799Ffxy.jpeg",
    count: 43,
    href: "/author",
    desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
    jobName: "Author Job",
    bgImage: day3,
    pdfUrl: "/Day3.pdf",
  },
  {
    id: 4,
    firstName: "Agnes",
    lastName: "Falconar",
    displayName: "DAY 4",
    email: "afalconar3@google.ru",
    gender: "Non-binary",
    avatar: "https://uifaces.co/our-content/donated/KtCFjlD4.jpg",
    count: 36,
    href: "/author",
    desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
    jobName: "Author Job",
    bgImage: day4,
    pdfUrl: "/Day4.pdf",
  },
  {
    id: 5,
    firstName: "Vita",
    lastName: "Tousy",
    displayName: "DAY 5",
    email: "vtousy4@elpais.com",
    gender: "Male",
    avatar: "https://uifaces.co/our-content/donated/xZ4wg2Xj.jpg",
    count: 38,
    href: "/author",
    desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
    jobName: "Author Job",
    bgImage: day5,
    pdfUrl: "/Day5.pdf",
  },
  {
    id: 6,
    firstName: "Donna",
    lastName: "Friar",
    displayName: "ADHD Conference",
    email: "dfriar5@telegraph.co.uk",
    gender: "Agender",
    avatar: "https://uifaces.co/our-content/donated/bUkmHPKs.jpg",
    count: 31,
    href: "/author",
    desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
    jobName: "Author Job",
    bgImage: ADHD,
    pdfUrl: "/ADHD.pdf",
  },
];

const SectionGridAuthorBox: FC<SectionGridAuthorBoxProps> = ({
  className = "",
  authors = DEMO_DATA,
  boxCard = "box1",
  gridClassName = "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 ",
}) => {
  const UNIQUE_CLASS =
    "SectionSliderNewCategories__" + "PageHome_s3" + useNcId();

  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: 6,
      gap: 5,
      bound: true,
      breakpoints: {
        1280: {
          perView: 5 - 1,
        },
        1024: {
          gap: 5,
          perView: 5 - 1,
        },
        768: {
          gap: 5,
          perView: 5 - 2,
        },
        640: {
          gap: 5,
          perView: 5 - 3,
        },
        500: {
          gap: 5,
          perView: 1.3,
        },
      },
    });
  }, [UNIQUE_CLASS]);
  const handleCardClick = (pdfUrl: string) => {
    window.open(pdfUrl, "_blank");
  };

  useEffect(() => {
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 100);
  }, [MY_GLIDEJS, UNIQUE_CLASS]);
  return (
    <div
      className={`cursor-pointer pl-4 nc-SectionGridAuthorBox relative ${className}`}
      data-nc-id="SectionGridAuthorBox"
    >
      {" "}
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Heading className=" mb-14">The Program</Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className=" w-full flex justify-between">
            {authors.map((author, index) => (
              <li
                key={index}
                style={{ width: 80 }}
                className={"w-full flex justify-center"}
                onClick={() => handleCardClick(author.pdfUrl)}
              >
                <CardAuthorBox2 key={author.id} author={author} />
              </li>
            ))}
          </ul>
        </div>

        {/* <NextPrev className="justify-center mt-16" /> */}

        {/* <div className={`grid gap-6 md:gap-8 ${gridClassName}`}>
        {authors.map((author: any, index: number) =>
          boxCard === "box2" ? (
            <CardAuthorBox2 key={author.id} author={author} />
          ) : (
            <CardAuthorBox
              index={index < 3 ? index + 1 : undefined}
              key={author.id}
              author={author}
            />
          )
        )}
      </div> */}
      </div>
    </div>
  );
};

export default SectionGridAuthorBox;
